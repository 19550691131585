import { useTranslation } from 'react-i18next';

import { useGetLocations } from 'entities/company/api';

import {
  ErrorBoundary,
  FormControlProps,
  SelectField,
} from 'shared/components';

import { FormField } from 'shared/components/Form';
import { ISelectFieldProps } from 'shared/components/Form/SelectField';

interface IProps extends FormControlProps {
  companyId: string;
  filters?: Record<string, unknown>;
  name?: string;
  inputProps?: Partial<ISelectFieldProps>;
}

const defaultName = 'locationIDs';

const LocationsFieldThrowable = ({
  companyId,
  filters,
  name = defaultName,
  ...restProps
}: IProps) => {
  const { t } = useTranslation('commonFields');
  const { isLoading, data } = useGetLocations(
    companyId,
    {
      ...(filters ? { customFilters: filters } : {}),
      sort: [{ name: 'name', sortDirection: 'asc' }],
      filters: 'true',
    },
    { useErrorBoundary: false },
  );

  const options = [
    { value: '', label: t('By Location') },
    ...(data
      ? data.data.data.map((location) => {
          return {
            value: location._id,
            label: location.name,
          };
        })
      : []),
  ];

  return (
    <FormField
      label={`${t('Locations')}:`}
      // {...restProps}
    >
      <SelectField
        name={name}
        isLoading={isLoading}
        options={options}
        placeholder={t('Select one')}
        isDisabled={restProps.isDisabled}
        {...restProps.inputProps}
      />
    </FormField>
  );
};

const LocationsField = (props: IProps) => {
  const { t } = useTranslation('commonFields');

  return (
    <ErrorBoundary
      fallback={() => (
        <FormField label={`${t('Locations')}:`}>
          <SelectField
            name={props.name || defaultName}
            isDisabled
          />
        </FormField>
      )}
    >
      <LocationsFieldThrowable {...props} />
    </ErrorBoundary>
  );
};

export { LocationsField };
