import { useTranslation } from 'react-i18next';

import { ErrorBoundary, SelectField } from 'shared/components';
import { FormField } from 'shared/components/Form';

import { useGetActions } from 'entities/tracking/api';

interface IProps {
  companyId: string;
}

const ViolationActionFieldThrowable = ({ companyId }: IProps) => {
  const { t } = useTranslation('commonFields');
  const { isLoading, data } = useGetActions(
    companyId,
    {
      sort: [{ name: 'name', sortDirection: 'asc' }],
      filters: 'true',
    },
    { useErrorBoundary: false },
  );

  const options = [
    { value: null, label: t('By Violation Action') },
    ...(data
      ? data.data.data.map((action) => {
          return {
            value: action._id,
            label: action.name,
          };
        })
      : []),
  ];

  return (
    <FormField label={`${t('Violation Action')}:`}>
      <SelectField
        name="violation.actionID"
        isLoading={isLoading}
        options={options}
        placeholder={t('Select one')}
      />
    </FormField>
  );
};

const ViolationActionField = (props: IProps) => {
  const { t } = useTranslation('commonFields');

  return (
    <ErrorBoundary
      fallback={() => (
        <FormField label={`${t('Violation Action')}:`}>
          <SelectField
            name="violation.actionID"
            isDisabled
          />
        </FormField>
      )}
    >
      <ViolationActionFieldThrowable {...props} />
    </ErrorBoundary>
  );
};

export { ViolationActionField };
