import { useTranslation } from 'react-i18next';

import { useGetDepartments } from 'entities/company/api';

import {
  ErrorBoundary,
  FormControlProps,
  ReactSelectProps,
  SelectField,
} from 'shared/components';

import { FormField } from 'shared/components/Form';

interface IProps extends FormControlProps {
  companyId: string;
  filters?: Record<string, unknown>;
  name?: string;
  inputProps?: ReactSelectProps;
}

const defaultName = 'departmentIDs';

const DepartmentsFieldThrowable = ({
  companyId,
  filters,
  name = defaultName,
  ...restProps
}: IProps) => {
  const { t } = useTranslation('commonFields');
  const { isLoading, data } = useGetDepartments(
    companyId,
    {
      ...(filters ? { customFilters: filters } : {}),
      sort: [{ name: 'name', sortDirection: 'asc' }],
      filters: 'true',
    },
    { useErrorBoundary: false },
  );

  const options = [
    { value: '', label: t('By Department') },
    ...(data
      ? data.data.data.map((department) => {
          return {
            value: department._id,
            label: department.name,
          };
        })
      : []),
  ];

  return (
    <FormField label={`${t('Departments')}:`}>
      <SelectField
        name={name}
        isLoading={isLoading}
        options={options}
        placeholder={t('Select one')}
        isDisabled={restProps.isDisabled}
        {...restProps.inputProps}
      />
    </FormField>
  );
};

const DepartmentsField = (props: IProps) => {
  const { t } = useTranslation('commonFields');
  return (
    <ErrorBoundary
      fallback={() => (
        <FormField label={`${t('Departments')}:`}>
          <SelectField
            name={props.name || defaultName}
            isDisabled
          />
        </FormField>
      )}
    >
      <DepartmentsFieldThrowable {...props} />
    </ErrorBoundary>
  );
};

export { DepartmentsField };
