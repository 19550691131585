import { useTranslation } from 'react-i18next';

import { ErrorBoundary, SelectField } from 'shared/components';
import { FormField } from 'shared/components/Form';
import { useGetVendors } from 'entities/vendor/api';

interface IProps {
  companyId: string;
  name?: string;
}

const VendorsFieldThrowable = ({ companyId, name = 'vendors[0]' }: IProps) => {
  const { t } = useTranslation('commonFields');
  const { isLoading, data } = useGetVendors(
    companyId,
    {
      sort: [{ name: 'name', sortDirection: 'asc' }],
      filters: 'true',
    },
    { useErrorBoundary: false },
  );

  const options = [
    { value: null, label: t('By Vendor') },
    ...(data
      ? data.data.data.map((vendor) => {
          return {
            value: vendor._id,
            label: vendor.name,
          };
        })
      : []),
  ];

  return (
    <FormField label={`${t('Vendors')}:`}>
      <SelectField
        name={name}
        isLoading={isLoading}
        options={options}
        placeholder={t('Select one')}
      />
    </FormField>
  );
};

const VendorsField = (props: IProps) => {
  const { t } = useTranslation('commonFields');

  return (
    <ErrorBoundary
      fallback={() => (
        <FormField label={`${t('Vendors')}:`}>
          <SelectField
            name="vendors"
            isDisabled
          />
        </FormField>
      )}
    >
      <VendorsFieldThrowable {...props} />
    </ErrorBoundary>
  );
};

export { VendorsField };
